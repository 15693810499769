import React, { useState } from "react";
import { InputGroup, Form, Button } from "react-bootstrap";
import { useAppDispatch, useAppSelector } from "../store/hooks";
import { setCity } from "../store/citySlice";

export const LocationInput = () => {
  const [manualCityInput, setManualCityInput] = useState("");
  const dispatch = useAppDispatch();
  const { city } = useAppSelector((state) => state.location);

  const handleManualCitySubmit = (
    event: React.FormEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
    submitCity();
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      event.preventDefault();
      submitCity();
    }
  };

  const submitCity = () => {
    if (manualCityInput.trim() !== "") {
      dispatch(setCity(manualCityInput.trim()));
    } else {
      // Set default city if no input provided
      dispatch(setCity("Berlin")); // Replace with your desired default city
    }
    setManualCityInput("");
  };

  const handleManualCityChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setManualCityInput(event.target.value);
  };

  return (
    <>
      <h4 style={{ paddingBottom: "1rem" }}>
        With <b>thousands</b> of chess players, we make it easy for you to meet
        and play.
        <br />
        Discover players in <b>{city}</b> and start playing today!
      </h4>
      <InputGroup
        hasValidation
        style={{ paddingBottom: "8rem", fontSize: "1.25rem", width: "22rem" }}
      >
        <Form.Control
          type="text"
          placeholder={`📍 ${city ? city : "Enter city"} `}
          value={manualCityInput}
          onChange={handleManualCityChange}
          onKeyDown={handleKeyDown} // Handle Enter key press
          style={{ height: "3rem", fontSize: "1.25rem" }}
        />
        <Button
          variant="outline-secondary"
          onClick={handleManualCitySubmit}
          disabled={manualCityInput.length < 3}
        >
          Search
        </Button>
      </InputGroup>
    </>
  );
};
