import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import { HeaderSection } from "../locationHero/HeaderSection";
import { PlayerSection } from "../playerSection/PlayerSection";
import { NavBar } from "./NavBar";
import { Profile } from "../profile/PlayerProfile";
import { Footer } from "./Footer";
import { Impressum } from "../legal/Impressum";
import { PrivacyPolicy } from "../legal/PrivacyPolicy";
import { TermsOfService } from "../legal/TermsOfService";
import { Contact } from "../legal/Contact";

const HomePage = () => (
  <>
    <HeaderSection />
    <PlayerSection />
  </>
);

const AppRouter = () => {
  return (
    <Router>
      <NavBar />
      <div style={{ paddingBottom: "4rem" }}>
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/profile" element={<Profile />} />
          <Route path="/impressum" element={<Impressum />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/terms-of-service" element={<TermsOfService />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
      </div>
      <Footer />
    </Router>
  );
};

export default AppRouter;
