import { useEffect, useState } from "react";

interface IQuote {
  quote: string;
  author: string;
  id: number;
}

export const ChessQuote = () => {
  const [quote, setQuote] = useState<IQuote>({ quote: "", author: "", id: 0 });
  useEffect(() => {
    const fetchQuotes = async () => {
      try {
        const response = await fetch("/assets/quotes/quotes.json");
        const quotes = await response.json();
        const randomQuote = getRandomQuote(quotes);
        setQuote(randomQuote);
      } catch (error) {
        console.error("Error fetching quotes:", error);
      }
    };

    fetchQuotes();
  }, []);

  const getRandomQuote = (quotes: IQuote[]) => {
    const randomIndex = Math.floor(Math.random() * quotes.length);
    return quotes[randomIndex];
  };

  return (
    <blockquote
      style={{
        fontFamily: "Georgia, serif",
        fontStyle: "italic",
        borderLeft: "5px solid #ccc",
        padding: "10px 20px",
        marginTop: "20px",
        background: "#f9f9f9",
      }}
    >
      <p
        style={{
          fontSize: "1.2em",
          marginBottom: "10px",
        }}
      >
        “{quote.quote}”
      </p>
      <footer
        style={{
          textAlign: "right",
          fontSize: "0.8em",
          fontWeight: "bold",
          color: "#555",
        }}
      >
        {quote.author}
      </footer>
    </blockquote>
  );
};
