import { Container } from "react-bootstrap";

export const PrivacyPolicy = () => {
  return (
    <Container style={{ marginTop: "5rem", marginBottom: "5rem" }}>
      <h2>Privacy Policy</h2>

      <p>
        This privacy policy outlines how Chess-RL collects, uses, and protects
        the personal data of our users. Please read this policy carefully to
        understand how we handle your personal information.
      </p>

      <h4>Data Collection</h4>

      <p>
        We collect personal data from users when they register an account. The
        types of personal data we collect may include name, email address, phone
        number, the city you are in and your chess rating .
      </p>

      <h4>Data Usage</h4>

      <p>
        We use the collected data for the following purposes: - To provide and
        maintain our services. - To communicate with users, including updates
        and promotional offers. - To improve our services and understand user
        preferences.
      </p>

      <h4>Data Sharing</h4>

      <p>
        We may share users' personal data with third parties in the following
        circumstances: - With service providers who assist in the operation of
        our website/application. - With business partners for joint promotional
        activities. - When required by law or to protect our rights.
      </p>

      <h4>Cookies and Tracking</h4>

      <p>
        Our website/application may use cookies or similar technologies to
        enhance user experience and track usage patterns. Users can control
        cookie preferences through their browser settings.
      </p>

      <h4>Data Security</h4>

      <p>
        We implement appropriate security measures to protect users' personal
        data from unauthorized access or disclosure. However, no method of
        transmission over the internet or electronic storage is entirely secure.
      </p>

      <h4>User Rights</h4>

      <p>
        Users have the right to: - Access their personal data and receive a copy
        of it. - Correct any inaccuracies in their personal data. - Request
        deletion of their personal data, subject to legal obligations.
      </p>

      <h4>Policy Updates</h4>

      <p>
        We may update this privacy policy from time to time. Changes will be
        posted on this page, and users will be notified accordingly. Continued
        use of our services after changes are made indicates acceptance of the
        updated policy.
      </p>

      <p>
        If you have any questions or concerns about our privacy practices,
        please contact us at darjusch.schrand@code.berlin.
      </p>
    </Container>
  );
};
