import { Container } from "react-bootstrap";

export const Contact = () => {
  return (
    <Container style={{ marginTop: "5rem", marginBottom: "5rem" }}>
      <h2>Contact Us</h2>

      <p>
        If you have any questions about our services or these Terms, please feel
        free to contact us using the information below:
      </p>

      <h3>Email</h3>
      <p>
        For general inquiries:{" "}
        <a href="mailto:darjusch.schrand@code.berlin">
          darjusch.schrand@code.berlin
        </a>
      </p>

      <h3>Phone</h3>
      <p>Customer support: +49 176 21930466</p>

      <h3>Address</h3>
      <p>
        ChessRL
        <br />
        Lohmühlenstraße 65
        <br />
        12435 Berlin
        <br />
        Germany
      </p>

      <p>We aim to respond to all inquiries within 5 business days.</p>
    </Container>
  );
};
