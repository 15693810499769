import React, {
  createContext,
  useContext,
  useState,
  useEffect,
  ReactNode,
} from "react";
import { createClient, SupabaseClient, Session } from "@supabase/supabase-js";

// Supabase setup
const supabaseUrl = "https://lrgrqtrinvgfwnudujyn.supabase.co";
// TODO - move this to .env
const supabaseKey =
  "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6ImxyZ3JxdHJpbnZnZndudWR1anluIiwicm9sZSI6ImFub24iLCJpYXQiOjE3MTgzMDc0OTYsImV4cCI6MjAzMzg4MzQ5Nn0.N6t3enTQw42WClgjX7PXLTHx4wJj36CMp_wfSwT9ryc";
export const supabase: SupabaseClient = createClient(supabaseUrl, supabaseKey);

// Types for context
interface AuthContextType {
  session: Session | null;
  supabase: SupabaseClient;
}

interface AuthProviderProps {
  children: ReactNode;
}

// Create context with default values
const AuthContext = createContext<AuthContextType | undefined>(undefined);

export const AuthProvider = ({ children }: AuthProviderProps) => {
  const [session, setSession] = useState<Session | null>(null);

  useEffect(() => {
    supabase.auth.getSession().then(({ data: { session } }) => {
      setSession(session);
    });

    const {
      data: { subscription },
    } = supabase.auth.onAuthStateChange((event, session) => {
      setSession(session);
    });

    return () => subscription.unsubscribe();
  }, []);

  return (
    <AuthContext.Provider value={{ session, supabase }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = (): AuthContextType => {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error("useAuth must be used within an AuthProvider");
  }
  return context;
};
