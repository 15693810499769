import { Container } from "react-bootstrap";
import { PlayerPreviewList } from "./PlayerPreviewList";
import { useAppSelector } from "../store/hooks";

export const PlayerSection = () => {
  const city = useAppSelector((state) => state.location.city);

  return (
    <Container style={{ marginTop: "4rem" }}>
      <h2>♟️ Players In {city || "Your City"}</h2>
      <p style={{ paddingTop: "1rem" }}>
        Here's a selection of players near you, who want to meet and play!
      </p>
      <PlayerPreviewList />
    </Container>
  );
};
