import { useState } from "react";
import { Button, Card, Col } from "react-bootstrap";
import { PlayerContact } from "./PlayerContact";
import { useAuth } from "../auth/AuthContext";
import { useNavigate } from "react-router-dom";

interface TPlayerPreview {
  image_path: string;
  playerName: string;
  blitz_rating: string;
  rapid_rating: string;
  email?: string;
  playerPhone?: string;
}

export const PlayerPreview = (playerInfo: TPlayerPreview) => {
  const [showContact, setShowContact] = useState(false);
  const navigate = useNavigate();
  const { session } = useAuth();

  const handleContactClick = () => {
    if (session) {
      setShowContact(true);
    } else {
      navigate("/profile");
    }
  };

  return (
    <>
      <Col xs={12} sm={6} md={4} lg={3} className="mb-4 d-flex">
        <Card
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
            transition: "transform 0.3s, box-shadow 0.3s",
          }}
          onMouseEnter={(e) => {
            e.currentTarget.style.transform = "translateY(-5px)";
            e.currentTarget.style.boxShadow = "0 12px 16px rgba(0, 0, 0, 0.2)";
          }}
          onMouseLeave={(e) => {
            e.currentTarget.style.transform = "translateY(0)";
            e.currentTarget.style.boxShadow = "0 4px 8px rgba(0, 0, 0, 0.1)";
          }}
        >
          <Card.Img
            variant="top"
            src={playerInfo.image_path}
            style={{ width: "100%", height: "200px", objectFit: "cover" }}
          />
          <Card.Body style={{ flex: "1 0 auto" }}>
            <Card.Title>{playerInfo.playerName}</Card.Title>
            <Card.Text>
              Blitz: {playerInfo.blitz_rating} <br />
              Rapid: {playerInfo.rapid_rating ? playerInfo.rapid_rating : "-"}
            </Card.Text>
            <Button
              variant="dark"
              onClick={() => {
                handleContactClick();
              }}
            >
              Contact
            </Button>
          </Card.Body>
        </Card>
      </Col>
      {showContact && (
        <PlayerContact
          show={showContact}
          onHide={() => setShowContact(false)}
          name={playerInfo.playerName}
          email={playerInfo.email}
          phone={playerInfo.playerPhone}
        />
      )}
    </>
  );
};
