import { useCallback, useEffect, useState } from "react";
import { supabase, useAuth } from "../auth/AuthContext";
import { PlayerProfileModal } from "./PlayerProfileModal";
import { Card, Container, ListGroup } from "react-bootstrap";
import { ChessQuote } from "../components/ChessQuote";
import SupabaseAuth from "../auth/SupabaseAuth";

export const Profile = () => {
  const { session } = useAuth();
  const [profileExists, setProfileExists] = useState(false);
  const [profileData, setProfileData] = useState<any>(null);
  const [profileModalShow, setProfileModalShow] = useState(false);
  const [authModalShow, setAuthModalShow] = useState(false);

  const fetchProfileData = useCallback(async () => {
    if (!session) {
      setAuthModalShow(true);
      return;
    }

    const {
      data: { user },
    } = await supabase.auth.getUser();
    const { data, error } = await supabase
      .from("profiles")
      .select("*")
      .eq("email", user?.email);

    if (error) {
      console.error("Error fetching profile:", error.message);
      return;
    }
    if (data && data.length > 0) {
      setProfileExists(true);
      setProfileData(data[0]);
    } else {
      setProfileExists(false);
      setProfileModalShow(true);
    }
  }, [session]);

  useEffect(() => {
    fetchProfileData();
  }, [fetchProfileData]);

  const handleCloseProfileModal = () => {
    setProfileModalShow(false);
  };

  const handleCloseAuthModal = () => {
    setAuthModalShow(false);
  };

  const handleSubmitProfileModal = async (formData: any) => {
    const {
      data: { user },
    } = await supabase.auth.getUser();
    const { error } = await supabase.from("profiles").insert([
      {
        name: formData.name,
        city: formData.city,
        blitz_rating: formData.blitz_rating,
        contact_preference: formData.contactPreference,
        phone: formData.phone,
        email: user?.email,
        user_id: user?.id,
      },
    ]);

    if (error) {
      console.error("Error inserting profile:", error.message);
    } else {
      await fetchProfileData(); // Fetch the updated profile data
      setProfileExists(true);
      handleCloseProfileModal();
    }
  };

  if (!session) {
    return <SupabaseAuth show={authModalShow} onHide={handleCloseAuthModal} />;
  }

  return (
    <>
      {profileModalShow && !profileExists ? (
        <PlayerProfileModal
          show={profileModalShow}
          onHide={handleCloseProfileModal}
          onSubmit={handleSubmitProfileModal}
        />
      ) : (
        <Container
          className="mt-5 d-flex justify-content-center"
          style={{
            minHeight: "50vh",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Card
            style={{
              width: "100%",
              maxWidth: "600px",
            }}
          >
            <Card.Header as="h1" className="text-center">
              {profileData?.name}
            </Card.Header>
            <Card.Body>
              <ListGroup variant="flush">
                <ListGroup.Item className="d-flex flex-column flex-sm-row">
                  <div
                    className="fw-bold"
                    style={{ minWidth: "100px", width: "200px" }}
                  >
                    CITY
                  </div>
                  <div>{profileData?.city}</div>
                </ListGroup.Item>
                <ListGroup.Item className="d-flex flex-column flex-sm-row">
                  <div
                    className="fw-bold"
                    style={{ minWidth: "100px", width: "200px" }}
                  >
                    BLITZ RATING
                  </div>
                  <div>{profileData?.blitz_rating}</div>
                </ListGroup.Item>
                <ListGroup.Item className="d-flex flex-column flex-sm-row">
                  <div
                    className="fw-bold"
                    style={{ minWidth: "100px", width: "200px" }}
                  >
                    CONTACT PREFERENCE
                  </div>
                  <div>
                    {profileData?.contact_preference ? "Public" : "Private"}
                  </div>
                </ListGroup.Item>
                <ListGroup.Item className="d-flex flex-column flex-sm-row">
                  <div
                    className="fw-bold"
                    style={{ minWidth: "100px", width: "200px" }}
                  >
                    EMAIL
                  </div>
                  <div>{profileData?.email}</div>
                </ListGroup.Item>
                <ListGroup.Item className="d-flex flex-column flex-sm-row">
                  <div
                    className="fw-bold"
                    style={{ minWidth: "100px", width: "200px" }}
                  >
                    PHONE
                  </div>
                  <div>{profileData?.phone ? profileData?.phone : "-"}</div>
                </ListGroup.Item>
              </ListGroup>
            </Card.Body>
          </Card>
        </Container>
      )}
      <Container className="mt-5 d-flex justify-content-center">
        <ChessQuote />
      </Container>
    </>
  );
};
