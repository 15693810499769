import React from "react";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import { Button, Image } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";
import { useNavigate, useLocation } from "react-router-dom";
import { supabase, useAuth } from "../auth/AuthContext";

export const NavBar = () => {
  const { session } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();

  const handleProfileClick = () => {
    navigate("/profile");
  };

  const handleSignOut = async () => {
    await supabase.auth.signOut();
    navigate("/");
  };

  return (
    <Navbar expand="lg" className="bg-body-tertiary" sticky="top">
      <Container>
        <LinkContainer to="/">
          <Navbar.Brand href="#home">
            <div
              style={{
                fontFamily: "Rubik, sans-serif",
                fontOpticalSizing: "auto",
                fontWeight: 500,
                fontStyle: "normal",
              }}
            >
              Chess Encounters
            </div>
          </Navbar.Brand>
        </LinkContainer>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto"></Nav>
          <Nav className="ms-auto">
            {session ? (
              <>
                {location.pathname !== "/profile" && (
                  <Button
                    variant="success"
                    onClick={handleProfileClick}
                    style={{ marginRight: "15px" }}
                  >
                    PROFILE
                  </Button>
                )}
                <Button variant="primary" onClick={handleSignOut}>
                  SIGN OUT
                </Button>
              </>
            ) : (
              <Button variant="primary" onClick={handleProfileClick}>
                SIGN IN
              </Button>
            )}
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};
