import React from "react";
import { Container, Nav } from "react-bootstrap";

export const Footer = () => {
  return (
    <footer
      style={{
        backgroundColor: "#f8f9fa",
        padding: "10px 0",
        position: "sticky",
        bottom: 0,
        width: "100%",
        marginTop: "auto",
      }}
    >
      <Container className="d-flex justify-content-between align-items-center">
        <Nav>
          <Nav.Link href="/impressum" className="text-muted">
            Impressum
          </Nav.Link>
          <Nav.Link href="/privacy-policy" className="text-muted">
            Privacy Policy
          </Nav.Link>
          <Nav.Link href="/terms-of-service" className="text-muted">
            Terms of Service
          </Nav.Link>
          <Nav.Link href="/contact" className="text-muted">
            Contact
          </Nav.Link>
        </Nav>
      </Container>
    </footer>
  );
};
