import { Row, Col, Button } from "react-bootstrap";
import { PlayerPreview } from "./PlayerPreview";
import { useEffect, useState } from "react";
import { supabase, useAuth } from "../auth/AuthContext";
import { IPlayerAuthFormData } from "../profile/PlayerProfileModal";
import { useNavigate } from "react-router-dom";
import { useAppSelector } from "../store/hooks"; // Import useAppSelector
import { RootState } from "../store/store"; // Import RootState

interface IPlayer {
  image_path: string;
  playerName: string;
  blitz_rating: string;
  rapid_rating: string;
  city: string;
  email?: string;
  phone?: string;
}

export const PlayerPreviewList = () => {
  const [players, setPlayers] = useState<IPlayer[]>([]);
  const { city } = useAppSelector((state: RootState) => state.location); // Use city from Redux store
  const { session } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    async function fetchData() {
      const { data, error } = await supabase.from("profiles").select("*");

      if (error) {
        console.error(error);
        return;
      }

      if (data) {
        const fetchedPlayers: IPlayer[] = data.map(
          (player: IPlayerAuthFormData) => ({
            image_path: player.image_path || "/assets/players/placeholder.png",
            playerName: player.name,
            blitz_rating: player.blitz_rating || "",
            rapid_rating: player.rapid_rating || "",
            city: player.city,
            email: player.email,
            phone: player.phone,
          })
        );

        const uniquePlayers = Array.from(
          new Set(fetchedPlayers.map((player) => player.email))
        ).map((email) => {
          return fetchedPlayers.find((player) => player.email === email)!;
        });

        setPlayers(uniquePlayers);
      }
    }

    fetchData();
  }, []);

  const isInTheCity = (player: IPlayer, city: string) => {
    return player.city.toLowerCase() === city.toLowerCase();
  };

  const isNotHimself = (player: IPlayer, email: string | undefined) => {
    return player.email !== email;
  };

  const filteredPlayers = players.filter(
    (player) =>
      isInTheCity(player, city) && isNotHimself(player, session?.user?.email)
  );

  const handleFirstPlayerClick = () => {
    navigate("/profile");
  };

  return (
    <Row>
      {filteredPlayers.length > 0 ? (
        filteredPlayers.map((player, index) => (
          <PlayerPreview
            key={index}
            image_path={player.image_path}
            playerName={player.playerName}
            blitz_rating={player.blitz_rating}
            rapid_rating={player.rapid_rating}
            email={player.email}
            playerPhone={player.phone}
          />
        ))
      ) : (
        <Col className="text-center mt-4">
          {session?.user ? (
            <p style={{ fontWeight: "bold", color: "blue" }}>
              Be patient, players will join soon!
            </p>
          ) : (
            <Button variant="primary" onClick={handleFirstPlayerClick}>
              Be the first to represent your city!
            </Button>
          )}
        </Col>
      )}
    </Row>
  );
};
