import React, { useEffect, useState } from "react";
import { Button, Form, Modal, Alert } from "react-bootstrap";
import { supabase } from "../auth/AuthContext";

interface IPlayerAuthModalProps {
  show: boolean;
  onHide: () => void;
  onSubmit: (formData: any) => void;
}

export interface IPlayerAuthFormData {
  name: string;
  city: string;
  blitz_rating: string;
  contactPreference: boolean;
  phone: string;
  image_path?: string;
  rapid_rating?: string;
  email: string;
}

export const PlayerProfileModal = (props: IPlayerAuthModalProps) => {
  const [step, setStep] = useState(1);
  const [formData, setFormData] = useState<IPlayerAuthFormData>({
    name: "",
    city: "",
    blitz_rating: "",
    contactPreference: false,
    phone: "",
    image_path: "/assets/placeholder.png",
    email: "",
  });
  const [error, setError] = useState<string | null>(null);
  const [userNames, setUserNames] = useState<string[]>([]);

  useEffect(() => {
    async function fetchData() {
      const { data, error } = await supabase.from("profiles").select("*");

      if (error) {
        console.error(error);
        return;
      }

      if (data) {
        console.log("data", data);
        setUserNames(data.map((profile: any) => profile.name));
        return;
      }
    }
    fetchData();
  }, []);

  const handleChange = (e: any) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const validateStep1 = () => {
    if (userNames.includes(formData.name)) {
      console.log("names", userNames, formData.name);
      setError("Name is already taken.");
      return false;
    }
    if (formData.name.length < 2) {
      setError("Name must be at least 2 characters long.");
      return false;
    }
    if (formData.city.length < 2) {
      setError("City must be at least 2 characters long.");
      return false;
    }
    const rating = parseInt(formData.blitz_rating, 10);
    if (isNaN(rating)) {
      setError("Blitz Rating must be a valid number.");
      return false;
    }
    return true;
  };

  const handleNext = () => {
    if (step === 1 && validateStep1()) {
      setError(null);
      setStep(step + 1);
    }
  };

  const handlePrevious = () => {
    setStep(step - 1);
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();
    props.onSubmit(formData);
  };

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">Register</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "10px",
            width: "20rem",
            margin: "auto",
          }}
        >
          {error && <Alert variant="danger">{error}</Alert>}
          <Form onSubmit={handleSubmit}>
            {step === 1 && (
              <>
                <Form.Group controlId="formName">
                  <Form.Label>Name</Form.Label>
                  <Form.Control
                    type="text"
                    name="name"
                    value={formData.name}
                    onChange={handleChange}
                    required
                  />
                </Form.Group>

                <Form.Group controlId="formCity">
                  <Form.Label>City</Form.Label>
                  <Form.Control
                    type="text"
                    name="city"
                    value={formData.city}
                    onChange={handleChange}
                    required
                  />
                </Form.Group>

                <Form.Group controlId="formRating">
                  <Form.Label>Blitz Rating</Form.Label>
                  <Form.Control
                    type="number"
                    name="blitz_rating"
                    value={formData.blitz_rating}
                    onChange={handleChange}
                    required
                  />
                </Form.Group>
              </>
            )}

            {step === 2 && (
              <>
                <Form.Group controlId="formContactPreference">
                  <Form.Label>
                    Would you like to be contacted by other players?
                  </Form.Label>
                  <Form.Check
                    type="radio"
                    name="contactPreference"
                    value="yes"
                    label="Yes"
                    checked={formData.contactPreference === true}
                    onChange={() =>
                      setFormData({ ...formData, contactPreference: true })
                    }
                  />
                  <Form.Check
                    type="radio"
                    name="contactPreference"
                    value="no"
                    label="No"
                    checked={formData.contactPreference === false}
                    onChange={() =>
                      setFormData({ ...formData, contactPreference: false })
                    }
                  />
                </Form.Group>

                {formData.contactPreference === true && (
                  <>
                    <Form.Group controlId="formPhone">
                      <Form.Label>Phone Number (optional)</Form.Label>
                      <Form.Control
                        type="tel"
                        name="phone"
                        value={formData.phone}
                        onChange={handleChange}
                      />
                    </Form.Group>
                  </>
                )}
              </>
            )}
          </Form>
        </div>
      </Modal.Body>
      <Modal.Footer>
        {step === 1 && (
          <Button variant="primary" onClick={handleNext}>
            Next
          </Button>
        )}
        {step === 2 && (
          <>
            <Button variant="secondary" onClick={handlePrevious}>
              Previous
            </Button>
            <Button variant="primary" onClick={handleSubmit}>
              Submit
            </Button>
          </>
        )}
      </Modal.Footer>
    </Modal>
  );
};
