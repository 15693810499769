import { Container } from "react-bootstrap";

export const TermsOfService = () => {
  return (
    <Container style={{ marginTop: "5rem", marginBottom: "5rem" }}>
      <h2>Terms of Service</h2>

      <p>
        These Terms of Service ("Terms") govern your use of ChessRL's website
        and services. By accessing or using our website, you agree to be bound
        by these Terms. Please read them carefully before using our services.
      </p>

      <h3>1. Use of Our Services</h3>

      <p>
        You must be at least 18 years old to use our services. You agree to use
        our services only for lawful purposes and in compliance with all
        applicable laws and regulations.
      </p>

      <h3>2. User Accounts</h3>

      <p>
        Some features of our services may require creating a user account. You
        are responsible for maintaining the confidentiality of your account
        credentials and for all activities that occur under your account. You
        agree to provide accurate and complete information when creating an
        account.
      </p>

      <h3>3. Content</h3>

      <p>
        Our services allow you to post, link, store, share, and otherwise make
        available certain information, text, graphics, videos, or other material
        ("Content"). You retain ownership of your Content, but you grant ChessRL
        a non-exclusive, royalty-free, worldwide, perpetual, and irrevocable
        license to use, reproduce, modify, adapt, publish, translate, create
        derivative works from, distribute, perform, and display your Content in
        connection with operating and promoting our services.
      </p>

      <h3>4. Intellectual Property Rights</h3>

      <p>
        All content and materials available on our website or through our
        services, including but not limited to text, graphics, logos, images,
        and software, are the property of ChessRL or its licensors and are
        protected by copyright, trademark, and other intellectual property laws.
        You agree not to reproduce, modify, distribute, display, or create
        derivative works of any such content without prior written permission
        from ChessRL.
      </p>

      <h3>5. Limitation of Liability</h3>

      <p>
        To the fullest extent permitted by law, ChessRL, its affiliates,
        directors, officers, employees, agents, suppliers, or licensors shall
        not be liable for any indirect, incidental, special, consequential, or
        punitive damages, including but not limited to damages for lost profits,
        goodwill, use, data, or other intangible losses, resulting from (i) your
        access to or use of or inability to access or use our services; (ii) any
        conduct or content of any third party on our services; (iii) any content
        obtained from our services; or (iv) unauthorized access, use, or
        alteration of your transmissions or content, whether based on warranty,
        contract, tort (including negligence), or any other legal theory,
        whether or not we have been informed of the possibility of such damage,
        and even if a remedy set forth herein is found to have failed of its
        essential purpose.
      </p>

      <h3>6. Governing Law</h3>

      <p>
        These Terms shall be governed by and construed in accordance with the
        laws of Berlin, Germany without regard to its conflict of law
        provisions.
      </p>

      <h3>7. Changes to Terms</h3>

      <p>
        ChessRL reserves the right, at its sole discretion, to modify or replace
        these Terms at any time. If a revision is material, we will provide at
        least 7 days' notice prior to any new terms taking effect. What
        constitutes a material change will be determined at our sole discretion.
      </p>

      <p>
        By continuing to access or use our services after any revisions become
        effective, you agree to be bound by the revised terms. If you do not
        agree to the new terms, please stop using the services.
      </p>

      <h3>8. Contact Us</h3>

      <p>
        If you have any questions about these Terms, please contact us at
        darjusch.schrand@code.berlin.
      </p>
    </Container>
  );
};
