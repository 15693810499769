import React from "react";
import { Auth } from "@supabase/auth-ui-react";
import { ThemeSupa } from "@supabase/auth-ui-shared";
import { Modal } from "react-bootstrap";
import { useAuth } from "./AuthContext";

export interface SupabaseModalProps {
  show: boolean;
  onHide: () => void;
}

export const SupabaseAuth: React.FC<SupabaseModalProps> = (props) => {
  const { session, supabase } = useAuth();

  if (!session) {
    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Body>
          <Auth
            supabaseClient={supabase}
            appearance={{ theme: ThemeSupa }}
            providers={[]}
          />
        </Modal.Body>
      </Modal>
    );
  } else {
    return <></>;
  }
};

export default SupabaseAuth;
